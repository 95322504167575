import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { MerchantRequest, SESSION_TIME } from 'src/assets/Global_Constants';
import { ENVIRONMENT } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public authToken: string;
  private userToken: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public currentUserSubject: BehaviorSubject<User>;
  private baseURL = ENVIRONMENT.API_HOST + ENVIRONMENT.BASE_URL;
  private spBaseURL = ENVIRONMENT.SP_BASE_URL;
  
  httpHeaders;
  options;
  twoFaAuthTypes: Array<{ type: string, label: string }> = [];
  twoFA = [];
  bearer = '';
  entityId ; 
  accountDetails = {
    accountNum : "",
    accountName:'',
    accountCurrency : '',
    indAmount: '',
    indRate:'',
    overlappingStatus: '',
    overlappingAuthorizer : '',
    amountlabel : '',
    rulelabel : '',
    remarks:''
  }
  overLappingRules = {
    selfauth: '',
    authRules : '',
    sellparseruleid : '',
    selectionflag: '',
    boxlabel: '',
    overlapflag: ''
  }
  merchantDetails: any;
  redirectFormValues: any;
  LoginAttempts: any;
  otpAttempts: number;
  jobId: string;
  authorizationToken:string;
  constructor(private http: HttpClient) {
    this.getHeaderOptions(1);
  }

  getHeaderOptions(httpcode: number) {
    const token = this.getAuthorizationToken();
    this.authToken = token != null ? token : 'Bearer';
    const entityId = sessionStorage.getItem('entityId');
    this.entityId = entityId != null ? entityId : 'EBI';

    this.httpHeaders = new HttpHeaders({
      'Accept': 'application/json',
      'Accept-Language': 'en',
      'Authorization': this.authToken,
      'Cache-Control': 'no-cache',
      'Channel-Id': 'BNK',
      'Client-Ip': '1.2.3.4',
      'Client-Timestamp': String(+new Date()),
      'Financial-Id': this.entityId,
      'Unique-Reference-Code': String(+new Date())
    });
    if(httpcode == 1) 
    this.httpHeaders.append({'Content-Type': 'application/json'});
    this.options = {
      headers: this.httpHeaders,
      observe: 'response',
      withCredentials: true
    };
    return this.options;
  }

  getNonAuthToken(sessionId : String){
    return this.http.get<any>(`${this.baseURL}`+'pre-auth/users?sessionId='+sessionId, this.getHeaderOptions(0));
  }

  login(params) {
    return this.http.post<any>(`${this.baseURL}`+'pre-auth/users/login?sessionId='+sessionStorage.getItem('sessionId') + '&userType='+sessionStorage.getItem('userType'),params, this.getHeaderOptions(1));
  }

  loginRetail(params){
    return this.http.post<any>(`${this.baseURL}`+'pre-auth/users/login?sessionId='+sessionStorage.getItem('sessionId')+'&userType='+sessionStorage.getItem('userType'),params, this.getHeaderOptions(1));
  }
  
  setAuthorizationToken = (authToken) => {
    this.authorizationToken = authToken;  
  }

  getAuthorizationToken = () => {
    return this.authorizationToken;
  }

  getTwoFaType() {
    this.twoFaAuthTypes = [];
    if(this.twoFA.length > 1){
      this.twoFA.forEach(ele => {
        if(ele == 'O'){
          this.twoFaAuthTypes.push({"type":"O","label":"OTP"})
        }
        if(ele == 'H'){
          this.twoFaAuthTypes.push({"type":"H","label":"TOKEN"})
        }
        if(ele == 'S'){
          this.twoFaAuthTypes.push({"type":"S","label":"TOKEN"})
        }
      })
    }else {
      if(this.twoFA[0] == 'O'){
        this.twoFaAuthTypes.push({"type":"O","label":"OTP"})
      }
      if(this.twoFA[0] == 'H'){
        this.twoFaAuthTypes.push({"type":"H","label":"TOKEN"})
      }
      if(this.twoFA[0] == 'S'){
        this.twoFaAuthTypes.push({"type":"S","label":"TOKEN"})
      }
    }
    return this.twoFaAuthTypes
  }

  setTwoFaType(faTypes) {
    this.twoFA = [];
    this.twoFA = faTypes 
  }

  getSmsToken() {
    return this.http.get<any>(`${this.baseURL}`+'auth/users/otps/send?sessionId='+sessionStorage.getItem('sessionId')+'&userType='+sessionStorage.getItem('userType'), this.getHeaderOptions(0));
  }

  validateToken(data) {
    return this.http.post<any>(`${this.baseURL}`+'auth/users/otps/validate?userType='+sessionStorage.getItem('userType'),data, this.getHeaderOptions(1));
  }

  getProfile() {
    return this.http.get<any>(`${this.baseURL}`+'user-accounts?sessionId='+sessionStorage.getItem('sessionId')+'&userType='+sessionStorage.getItem('userType'), this.getHeaderOptions(0));
  }

  getAccountNumberDetails(accNum : number) {
    return this.http.get<any>(`${this.baseURL}`+'user-accounts/'+accNum+'?idType=accountNumber&sessionId='+sessionStorage.getItem('sessionId'), this.getHeaderOptions(0));
  }

  getOverlaps(dat) {
    return this.http.post<any>(`${this.baseURL}`+'transaction/workflow?sessionId='+sessionStorage.getItem('sessionId'),dat, this.getHeaderOptions(1));
  }
  transactionCreation(data) {
    return this.http.post<any>(`${this.baseURL}`+'transaction/create?sessionId='+sessionStorage.getItem('sessionId'),data, this.getHeaderOptions(1));
  }

  updateTransactionStatus(obj) {
    return this.http.put<any>(`${this.baseURL}`+'transaction?sessionId='+sessionStorage.getItem('sessionId'), obj,this.getHeaderOptions(1));
  }

  redirectToSB(id) {
    return this.http.get<any>(`${this.baseURL}`+'transaction/redirect?sessionId='+id,this.getHeaderOptions(0));
  }

  logout() {
    sessionStorage.removeItem('currentUser');
    this.userToken.next(null);
  }

  setReturnFormValues(data: MerchantRequest) {
	  this.redirectFormValues = data;
  }

  getReturnFormValues(){
    return this.redirectFormValues;
  }

  setLoginAttempts(data: number) {
	  this.LoginAttempts = data;
  }

  getLoginAttempts(){
    return this.LoginAttempts;
  }

  setOTPAttempts(data: number) {
	  this.otpAttempts = data;
  }

  getOTPAttempts(){
    return this.otpAttempts;
  }
  
authenticateSmartPassUser() {
    return this.http.get<any>(
      `${this.spBaseURL}`+'/v1/smart-pass/authorization/job/status?sessionId='+sessionStorage.getItem('sessionId')+'&jobId='+this.getJobId(),
      this.getHeaderOptions(1)
    );
  }

  isSPEligible() {
    return this.http.get<any>(`${this.spBaseURL}`+'/v1/smart-pass/authorization/eligible?sessionId='+sessionStorage.getItem('sessionId'), this.getHeaderOptions(1));

  }

  transactionAuth() {
    return this.http.get<any>(
      `${this.spBaseURL}`+'/v1/smart-pass/authorization/transaction?sessionId='+sessionStorage.getItem('sessionId'),
      this.getHeaderOptions(1)
    );
  }

  setJobId(jobId) {
    this.jobId = jobId;
  }

  getJobId() {
    return this.jobId;
  }
  
}
