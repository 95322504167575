import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { ButtonComponent } from './components/form/button/button.component';
import { InputComponent } from './components/form/input/input.component';
import { NumberMaskPipe } from './custom-directives/number-mask-pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { SliderComponent } from '../modules/login/slider/slider.component';
import { DigitOnlyNoPasteDirective } from './custom-directives/digit-only-no-paste.directive';
import { BlockCopyPasteDirective } from './custom-directives/bock-copy-paste.directive';
import { ConfirmationDialogComponent } from '../modules/smart-business/components/confirmation-dialog/confirmation-dialog.component';


@NgModule({
  declarations: [HeaderComponent, FooterComponent, ButtonComponent,ConfirmationDialogComponent,
    InputComponent,NumberMaskPipe,LoaderComponent,SliderComponent,DigitOnlyNoPasteDirective,BlockCopyPasteDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTabsModule,
    HttpClientModule,
    MatRadioModule,
    MatDialogModule
  ],
  exports: [HeaderComponent,
    FooterComponent,
    SliderComponent,
    DigitOnlyNoPasteDirective,
    FormsModule,
    LoaderComponent,
    ButtonComponent,
    InputComponent,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTabsModule,
    HttpClientModule,
    MatRadioModule,
    MatDialogModule,
    NumberMaskPipe,
    BlockCopyPasteDirective,
    ConfirmationDialogComponent]
})
export class SharedModule { }
