const detectContainerEnvironment = () => {
  const url = window.location.hostname.split('.')[0];
  let dynamicUrlConstant;
  if (url === 'paymentgatewaysit') {
    dynamicUrlConstant = 'https://pg.cloudsit.emiratesnbd.com'
    } else if (url === 'paymentgatewayuat') {
      dynamicUrlConstant = 'https://paymentgatewayuat.emiratesnbd.com'
    } else if (url === 'paymentgatewaypt') {
      dynamicUrlConstant = 'https://paymentgatewaypt.cloudpt.emiratesnbd.com'
    } else if (url === 'paymentgateway') {
      dynamicUrlConstant = 'https://paymentgateway.emiratesnbd.com'
    }

  return dynamicUrlConstant;
};

const detectSPEnvironment = () =>{
  const url = window.location.hostname.split('.')[0];
  let dynamicUrlConstant;
  if (url === 'paymentgatewaysit') {
    dynamicUrlConstant = 'https://paymentgatewayuat.clouduat.emiratesnbd.com';
  } else if (url === 'paymentgatewayuat') {
    dynamicUrlConstant = 'https://paymentgatewayuat.clouduat.emiratesnbd.com';
  } else if (url === 'paymentgatewaypt') {
    dynamicUrlConstant = 'https://paymentgatewayuat.clouduat.emiratesnbd.com';
  } else if (url === 'paymentgateway') {
    dynamicUrlConstant = 'https://paymentgateway.emiratesnbd.com';
  }

  return dynamicUrlConstant;
};

export const ENVIRONMENT = {
  PRODUCTION: false,
  API_HOST: detectContainerEnvironment(),
  BASE_URL: '/engagement/payment-gateway-bff/v1/',
  SP_BASE_URL: detectSPEnvironment() + '/engagement/payment-gateway-bff',
  IS_SP_ENABLED:true
};
