import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataSharedService } from '../../../core/services/data-shared.service';
import { Retail_QuickTips, businessOnline_QuickTips, Otp_QuickTips } from 'src/assets/Global_Constants';


@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnChanges {
  @Input() content: number;
  @Input() page: String;
  quickTips: string[]  = [];
  oTPTips: string[] = [];
  constructor(private sharedData: DataSharedService) { }

  ngOnInit() {
    if (sessionStorage.getItem('type') === 'CORP')
      this.quickTips = businessOnline_QuickTips;
    else
      this.quickTips = Retail_QuickTips;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.content == 1) 
      this.quickTips = businessOnline_QuickTips;
    else
      this.quickTips = Retail_QuickTips;
    
    //Disable for OTP quick tips

    // if(this.page == 'otp')
    //   this.oTPTips = Otp_QuickTips;
  }


}
