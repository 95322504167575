import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: 'button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() label: string;
  @Input() customClass: string;
  @Input() disabled: boolean;
  @Input() testAutomation: string;
  @Input() type = 'button';

  @Output() callFunction = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClick(event) {
    this.callFunction.emit();
  }
}
