import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appBlockCopyPaste]'
})
export class BlockCopyPasteDirective {

 
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  inputElement: any;
  public replaceVariable: any = /\D/g;
  public regx = '^[a-zA-Z0-9 \'\-]+$';

  constructor(public element: ElementRef) {
    this.inputElement = element.nativeElement;
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  @HostListener('paste', ['$event'])
  blockPaste(event: KeyboardEvent) {
    event.preventDefault();
  }

  @HostListener('copy', ['$event'])
  blockCopy(event: KeyboardEvent) {
    event.preventDefault();
  }

  @HostListener('cut', ['$event'])
  blockCut(event: KeyboardEvent) {
    event.preventDefault();
  }

}
