import { Route } from '@angular/router';
import { IUiRoute } from './UiRoute';

export const routes: Array<Route | IUiRoute> = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('../modules/login/login.module').then(m => m.LoginModule),
    label: "Dashboard",
    description: "Entry point",
    image: '',
  },
  {
    path: 'smart-business',
    loadChildren: () => import('../modules/smart-business/smart-business.module').then(m => m.SmartBusinessModule),
    label: "Smart Business",
    description: "Smart Business entry point",
    image: '',
  },

  // {
  //   path: 'simulator',
  //   loadChildren: () => import('../modules/simulator/simulator.module').then(m => m.SimulatorModule),
  //   label: "Simulator",
  //   description: "CB Merchant Simulator",
  //   image: '',
  // }
];



