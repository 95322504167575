import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { AuthenticationService } from './core/services/authentication-service.service';
import { User } from './core/models/user';
import { ThemeService } from './core/services/theme.service';
import { DataSharedService } from './core/services/data-shared.service';
import { PlatformLocation, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { INVALID_HASH, STATUS_CANCELLED, SESSION_TIME, SESSION_EXPIRED, Warning_Message, IDLE_TIME } from 'src/assets/Global_Constants';
import { ConfirmationDialogComponent } from './modules/smart-business/components/confirmation-dialog/confirmation-dialog.component';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentUser: User;
  private destroyed$ = new Subject();

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private themeService: ThemeService,
    private sharedData: DataSharedService,
    public dialog: MatDialog,
    private location: PlatformLocation,
    private loc: Location,
    private http: HttpClient,
  ) {
  }
  ngOnInit() {
    const entityIdMap = this.activateRoute.queryParams.subscribe(params => {
      if (params?.routeId) {
        this.authenticationService.getNonAuthToken(params?.routeId).subscribe((res) => {
          this.authenticationService.setAuthorizationToken('Bearer ' + res['body']);
          this.authenticationService.redirectToSB(params?.routeId)
            .subscribe(response => {
              sessionStorage.setItem("status", "redirect");
              this.authenticationService.setReturnFormValues(response['body']);
              this.router.navigateByUrl('smart-business/result', { skipLocationChange: true });
            });
        });
      }else if (params?.sessionId){
      if (params?.entityId?.toUpperCase() === 'EIB' || sessionStorage.getItem('entityId')?.toUpperCase() === 'EIB') {
        sessionStorage.setItem('entityId', 'EIB');
        this.themeService.setEITheme();
      } else {
        sessionStorage.setItem('entityId', 'EBI');
        this.themeService.setEnbdTheme();
      }
      if (params?.type?.toUpperCase() === 'RETL' || sessionStorage.getItem('type')?.toUpperCase() === 'RETL') {
        sessionStorage.setItem('type', 'RETL');
        sessionStorage.setItem('userType', 'RETAIL');
      } else {
        sessionStorage.setItem('type', 'CORP');
        sessionStorage.setItem('userType', 'CORPORATE');
      }

      if (params?.sessionId) {
        sessionStorage.setItem('sessionId', params?.sessionId)
        // if(params["sessionId"] == "null"){
        //   this.Redirection(INVALID_HASH);
        // }
        setTimeout(() => {
          this.expiretime();
        }, SESSION_TIME * 1000);
      }
      this.sharedData.sendData({ 'entityId': sessionStorage.getItem('entityId'), 'type': sessionStorage.getItem('type') });
      this.loc.replaceState("/");
    }
    });

  }

  myBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf("MSIE") != -1) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  Redirection(data) {
    if(! (sessionStorage.getItem("status") == null && sessionStorage.getItem("entityId") == null)){
    this.authenticationService.getNonAuthToken(sessionStorage.getItem('sessionId')).subscribe((res) => {
      this.authenticationService.setAuthorizationToken('Bearer ' + res['body']);
      this.authenticationService.updateTransactionStatus(data)
        .subscribe(response => {
          this.authenticationService.setReturnFormValues(response['body']);
          sessionStorage.setItem("status", "failure");
          this.router.navigateByUrl('smart-business/result', { skipLocationChange: true });
        });
    });
  }
  }

  expiretime() {
    if(! (sessionStorage.getItem("status") == null && sessionStorage.getItem("entityId") == null)){
    this.authenticationService.getNonAuthToken(sessionStorage.getItem('sessionId')).subscribe((res) => {
      this.authenticationService.setAuthorizationToken('Bearer ' + res['body']);
      this.authenticationService.updateTransactionStatus(SESSION_EXPIRED)
        .subscribe(response => {
          this.authenticationService.setReturnFormValues(response['body']);
          sessionStorage.setItem("status", "failure");
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: { "MsgInfo": true, "Message": Warning_Message, "id": "OK" }
          });
          setTimeout(() => {
            this.dialog.closeAll();
            this.router.navigateByUrl('smart-business/result', { skipLocationChange: true });
          },IDLE_TIME * 1000)
          dialogRef.afterClosed().subscribe((res: any) => {
            if (res) {
              this.router.navigateByUrl('smart-business/result', { skipLocationChange: true });
            }
          })
        });
    });
  }
  }
}
