import { Component, ViewChild, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


/* tslint:disable */
const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true,
};
/* tslint:enable */
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR]  // declare custom value accessor in provider list
})
export class InputComponent implements ControlValueAccessor {

  @ViewChild('id') id: any;

  @Input() type: string;
  @Input() required: boolean;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() iconPath: string;
  @Input() placeholder: string;
  @Input() testAutomation: string;
  @Input() showErrors = true;
  @Input() disabled: boolean;
  @Input() uniqueID: string;
  @Input() show: boolean;
  @Input() directive: any;
  @Input() autocomplete: string;
  // @Input( ) textMask:any; Mask or any other module might be also use value accessor,
  //  Multiple value accessor is not allowed on same element

  // declare input initial value
  public componentValue:any;

  // declare a function for getting callback on each input change
  propagateChange = (_: any) => {
  }

  // declare a function for fetting callback on touched
  propagateTouch = () => { };

  constructor() { }

  // interface method write external value in the component
  writeValue(obj: any): void {
    this.componentValue = obj;
    this.propagateChange(this.componentValue);
  }

  // interface method to be called on each input
  registerOnChange(fn: any): void {
    this.propagateChange = fn;

  }

  // interface method to be called on each touched
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }


  // interface method to be called on change disable property
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  // custom method call on (input) event
  handelChange() {
    this.propagateChange(this.componentValue);
  }

  // custom method call on (blur) event
  handelTouch() {
    this.propagateTouch();
  }

}
