import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharedService } from '../../../core/services/data-shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  entityId ;
  type;
  @ViewChild('header') header;
  constructor(private sharedData: DataSharedService) { }

  ngOnInit() {
    document.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop >= 100) {
        this.header.nativeElement.classList.add('shrink');
      } else {
        this.header.nativeElement.classList.remove('shrink');
      }
    });
    this.sharedData.getData().subscribe(data => {
      this.entityId = data['entityId'];
      this.type = data['type'];
      // sessionStorage.setItem('type', data['type']);
    })
  }

  getClassProperty() {
    let classType;
    if (this.entityId === "EIB") {
      if (this.type === 'CORP') {
        classType = "login-header-eib-business";
      } else {
        classType = "login-header-eib";
      }
    }

    else {
      if (this.type === 'CORP') {
        classType = "login-header-ebi-business";
      } else {
        classType = "login-header-ebi";
      }
    }
    return classType;
  }

}
