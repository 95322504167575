import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  themeData;
  constructor(private http: HttpClient) { }

  setEnbdTheme() {
    this.http.get('assets/tokens/enbd/tokens.json').subscribe((data) => {
      this.themeData = data;
      const keys = Object.keys(this.themeData);
      keys.forEach(element => {
        document.body.style.setProperty('--' + element, this.themeData[element]);
      });
    });
  }

  setEITheme() {
    this.http.get('assets/tokens/ei/tokens.json').subscribe((data) => {
      this.themeData = data;
      const keys = Object.keys(this.themeData);
      keys.forEach(element => {
        document.body.style.setProperty('--' + element, this.themeData[element]);
      });
    });
  }
}
