import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  showMsg: boolean;
  showOk: boolean;

  constructor(private dialogRef : MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.dialogRef.disableClose = true;
     }

  ngOnInit(): void {
    if(this.data.MsgInfo){
      this.showMsg  = true;
    }
    this.showOk = this.data.id == "OK" ? true : false;
  }

  onButtonClicked(val: boolean){
    this.dialogRef.close(val);
  }

}
