export const LOGIN_ATTEMPTS = 3;
export const OTP_ATTEMPTS = 3;
export const OTP_TIMER = 90;
export const SESSION_TIME = 900; //15 min * 60
export const IDLE_TIME = 300; //5 min * 60

export const Service_Unavailable_Message = "Service is currently unavailable"
export const Service_Unavailable_Error_Message = "We're sorry. Our system is temporarily unavailable. Please try again later."
export const API_Error_Message = "There is some technical problem, Please contact to Administrator"
export const Insufficient_Balance_Message = "Insufficient Balance !!";
export const Send_OTP_Error_Message = "Could not send the OTP";
export const Valid_Pin_Message = "Please enter valid Pin";
export const Validate_OTP_Error_Message = "Could not validate the pin";
export const Warning_Message = "Session Expired";
export const Confirmation_Dialog_Message = "Are you sure you want to cancel the Transaction?";
export const businessOnline_QuickTips = ['Never share your Subscriber ID, User ID, Password or Security Token device with anyone', 'businessONLINE access will be disabled after five consecutive, invalid login attempts'];
export const Retail_QuickTips = ['How to ensure online security', 'How to reset your Online Banking Password'];
export const Generic_Message = "Thank you !";
export const Success_Message = "Payment Made Successfully";
export const Otp_QuickTips = [
	'Please select OTP from “Choose Option” if you wish to receive the pin on your registered mobile phone.',
	'1) Click on “Generate/Regenerate OTP” button.',
	'2) Once you receive the OTP, Enter the PIN in the field.',
	'4) Click on “SUBMIT”.',
	'Please select Token from “Choose Option” if you wish to use token device issued.',
	'1) Enter the 6 digit number displayed on your token device in the field.',
	'2) Click on “SUBMIT”.',
	'In case you are experiencing difficulties logging in, delete the temporary files and cookies from your browser.',
]
export enum STATUS_CANCELLED {
    "status" = "CANCELLED",
	"statusDescription" = "Transaction Cancelled by User",
	"statusCode" = "201"
}

export enum SELF_AUTH_SUBMITTED {
    "status" = "SUBMITTED",
	"statusDescription" = "Authorization Pending",
	"statusCode" = "210"
}

export enum PROCESSED {
    "status" = "PROCESSED",
	"statusDescription" = "Transaction processed successfully",
	"statusCode" = "000"
}

export enum INVALID_LOGIN {
    "status" = "INVALID_LOGIN",
	"statusDescription" = "Invalid bank credentials",
	"statusCode" = "213"
}

export enum INVALID_HASH {
    "status" = "INVALID_HASH",
	"statusDescription" = "Invalid Hash Received",
	"statusCode" = "214"
}

export enum SYSTEM_ERROR {
    "status" = "SYSTEM_ERROR",
	"statusDescription" = "System Error",
	"statusCode" = "299"
}

export enum REDIRECT_TO_SB {
    "status" = "REDIRECT_TO_SB",
	"statusDescription" = "Transaction redirected to SB-2.0",
	"statusCode" = "111"
}

export enum NO_ENTITLEMENTS {
    "status" = "NO_ENTITLEMENTS",
	"statusDescription" = "Transactions not allowed",
	"statusCode" = "266"
}

export enum SESSION_EXPIRED {
    "status" = "SESSION_EXPIRED",
	"statusDescription" = "Transaction timed out",
	"statusCode" = "258"
}

export enum REJECTED {
    "status" = "REJECTED",
	"statusDescription" = "Transaction Rejected",
	"statusCode" = "295"
}

export class MerchantRequest{
	tranId : string ; 
	ppVersion : string ; 
	ppTxnType : string ; 
	ppBankID : string ; 
	ppLanguage : string ; 
	ppMerchantID : string ; 
	ppSubMerchantId : string ;
	ppProductID : string ;  
	ppTxnRefNo : string ; 
	ppRetreivalReferenceNo : string ;
	ppAmount : string ; 
	ppTxnCurrency : string ; 
	ppTxnDateTime : string ; 
	ppBillReference : string ; 
	ppreturnURL: string;
	ppSecureHash : string ; 
	ppmbf1 : string ; 
	ppmbf2 : string ; 
	ppmbf3 : string ; 
	ppmbf4 : string ; 
	ppmbf5 : string ; 
	ppmpf1 : string ; 
	ppmpf2 : string ; 
	ppmpf3 : string ; 
	ppmpf4 : string ; 
	ppmpf5 : string ; 
	ppMerchantName : string ; 
	ppMCC : string ; 
	ppSubMerchantName : string ; 
	ppPassword : string ; 
	ppDescription : string ; 
	ppTxnExpiryDateTime : string ; 
	ppResponseCode : string ; 
	ppResponseMessage : string ;  
	ppAuthCode : string ; 
	ppSettlementExpiry : string ; 
	ppCustomerIBAN : string ; 
	
}